import React from 'react';
import Layout from '../components/layout';
import ContentAllExceptIndex from '../components/content-all-except-index';
import '../components/layout.css';
import ContentThd from '../components/content-thd';
import SEO from '../components/seo';
import Footer from '../components/footer';
import ContentFth from '../components/content-fth';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import GlobalCTA from '../components/globalcta';
export default (location) => {
	const url = location.uri;
	const { edges } = location.data.allMarkdownRemark;
	return (
		<Layout loc_to_news={url}>
			<SEO title='Cortex - News & Events' />
			<ContentAllExceptIndex title='news'>
				<div className='cortex-home-content-nd-contact'>
					News and Events
					<div className='cortex-all-content-except-index-p-contact'>
						<p>
							Cortex provides insight and guidance into timely IoT, Big Data and Data Visualization
							trends, issues and solutions as a guest speaker at industry events and as thought leaders
							cited by news
						</p>
					</div>
				</div>
			</ContentAllExceptIndex>
			<ContentThd>
				<div style={{ marginTop: 60 }}>
					{edges.map((item, i) => {
						return (
							<React.Fragment>
								<div className='cortex-news-content'>
									<div className='cortex-news-content-container'>
										<div className='cortex-news-content-title'>{item.node.frontmatter.title}</div>
										<div className='cortex-news-content-date'>{item.node.frontmatter.date}</div>
										{item.node.frontmatter.image && (
											<Img
												className='cortex-news-img'
												sizes={item.node.frontmatter.image.childImageSharp.sizes}
											/>
										)}
										<div className='cortex-news-content-p'>{item.node.excerpt}</div>
										{
											item.node.frontmatter.type_of_link === 'external' ? 
											<a
												style={{ textDecoration: 'none' }}
												href={item.node.frontmatter.link}
												rel="noopener noreferrer"
												target='_blank'
											>
												<div className='cortex-news-content-read-more'>Read more</div>
											</a> :
											<div className='cortex-news-content-read-more'>
												<Link
													style={{ textDecoration: 'none', color: '#4d9de0' }}
													to={item.node.fields.slug}
												>
													Read more
												</Link>
											</div>}
									</div>
								</div>
							</React.Fragment>
						);
					})}
				</div>
			</ContentThd>
			<ContentFth>
				<GlobalCTA />
			</ContentFth>
			<Footer loc_to_news={url} />
		</Layout>
	);
};

export const pageQuery = graphql`
	{
		allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
			edges {
				node {
					fields {
						slug
					}
					excerpt(pruneLength: 2000)
					frontmatter {
						title
						date(formatString: "MMMM DD, YYYY")
						author
						position
						link
						type_of_link
						image {
							publicURL
							childImageSharp {
								sizes(quality: 100) {
									...GatsbyImageSharpSizes
								}
							}
						}
					}
				}
			}
		}
	}
`;
